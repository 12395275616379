import Vue, { PropType } from 'vue';
import IconBase from '../icon';
import { TdIconSVGProps, IconProps, SVGJson } from '../utils/types';
import useSizeProps from '../utils/use-size-props';

const element: SVGJson = {"tag":"svg","attrs":{"fill":"none","viewBox":"0 0 24 24","width":"1em","height":"1em"},"children":[{"tag":"path","attrs":{"fill":"currentColor","d":"M7.5 1h9v3H22v2h-2.03l-.5 17H4.53l-.5-17H2V4h5.5V1zm2 3h5V3h-5v1zM6.03 6l.44 15h11.06l.44-15H6.03zm3.14 3.26L12 12.09l2.83-2.83 1.41 1.41-2.83 2.83 2.83 2.83-1.41 1.41L12 14.91l-2.83 2.83-1.41-1.41 2.83-2.83-2.83-2.83 1.41-1.41z"}}]};

const Delete1 = Vue.extend<IconProps>({
  name: 'Delete1Icon',
  functional: true,
  props: {
    // small/medium/large/xl/18px/2em
    size: {
      type: String,
    },
    onClick: {
      type: Function as PropType<TdIconSVGProps['onClick']>,
    },
  },
  render(createElement, context) {
    const { props, data } = context;
    const { size, ...otherProps } = props;

    const { className, style } = useSizeProps(size);
    const fullProps = {
      ...otherProps || {},
      id: 'delete-1',
      icon: element,
      staticClass: className,
      style,
    };
    data.props = fullProps;
    return createElement(IconBase, data);
  },
});

export default Delete1;
