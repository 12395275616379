import Vue, { PropType } from 'vue';
import IconBase from '../icon';
import { TdIconSVGProps, IconProps, SVGJson } from '../utils/types';
import useSizeProps from '../utils/use-size-props';

const element: SVGJson = {"tag":"svg","attrs":{"fill":"none","viewBox":"0 0 24 24","width":"1em","height":"1em"},"children":[{"tag":"path","attrs":{"fill":"currentColor","d":"M20.5 5.83A10.49 10.49 0 001.56 10.9l-.1.99 1.98.2.1-.99a8.5 8.5 0 0115.67-3.6H15.5v2h7v-7h-2v3.33zm.06 6.07l-.1 1a8.5 8.5 0 01-15.67 3.6H8.5v-2h-7v7h2v-3.33a10.48 10.48 0 0018.94-5.07l.1-.99-1.98-.2z"}}]};

const Refresh = Vue.extend<IconProps>({
  name: 'RefreshIcon',
  functional: true,
  props: {
    // small/medium/large/xl/18px/2em
    size: {
      type: String,
    },
    onClick: {
      type: Function as PropType<TdIconSVGProps['onClick']>,
    },
  },
  render(createElement, context) {
    const { props, data } = context;
    const { size, ...otherProps } = props;

    const { className, style } = useSizeProps(size);
    const fullProps = {
      ...otherProps || {},
      id: 'refresh',
      icon: element,
      staticClass: className,
      style,
    };
    data.props = fullProps;
    return createElement(IconBase, data);
  },
});

export default Refresh;
